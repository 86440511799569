export const getDocumentShotType = (val: string | undefined | null): string => {
  if (val === undefined || val === null) { return '' }
  return val.substring(0, 6)
}

export const getDocumentName = (type: string | undefined | null): string => {
  const shortType = getDocumentShotType(type)
  switch (shortType) {
    case 'F01033':
      return 'Декларація'
    case 'F01331':
      return 'ЄСВ'
    case 'F01032':
      return 'Декларація - військовий стан'
    case 'F13918':
      return '5-ПРРО'
    case 'F13021':
      return 'Витяг з реєстру'
    case 'F01020':
      return 'Застосування спрощенної системи'
    case 'F13166':
      return '1-ПРРО'
    case 'F13120':
      return '20-ОПП'
    case 'F13911':
      return 'ЕЦП'
    case 'F13002':
      return 'Стан розрахунків'
  }
  return ''
}

export const isDocumentView = (type: string | undefined | null): boolean => {
  switch (type) {
    case 'F1300204':
    case 'F0103308':
    case 'F0103309':
    case 'F0133108':
    case 'F1499102':
    case 'J1499102':
    case 'F1391103':
    case 'F1391104':
    case 'FTEXT':
      return true
  }
  return false
}

export const documentViewVisibility = (type: string | undefined | null): string => {
  return isDocumentView(type) ? 'visible' : 'hidden'
}
