import { Form, Spinner, Stack, Table } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useEffect, useState } from 'react'
import api from '../../api'
import { FaAngleDown, FaAngleUp, FaRegFileExcel } from 'react-icons/fa'
import DebtTableRow from './debtTableRow'
import type { IAccountDebt, IDebt } from '../../interfaces/debt'
import './debtTable.scss'

interface DebtTableProps {
  searchParams: URLSearchParams
  handleSelectionChanged: (data: IDebt[] | null) => void
  handleTotal: (total: number) => void
}

const DebtTable = ({ searchParams, handleSelectionChanged, handleTotal }: DebtTableProps) => {
  const [data, setData] = useState<IAccountDebt[] | null>(null)
  const [reloadData, setReloadData] = useState<Date>(new Date())
  const [offset, setOffset] = useState<number | null>(null)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [collapseAll, setCollapseAll] = useState<boolean>(false)

  useEffect(() => {
    data?.forEach(item => {
      item.debt?.forEach(debt => {
        debt.isChecked = selectAll
      })
    })
  }, [selectAll])

  useEffect(() => {
    data?.forEach(item => {
      item.isShowDetails = collapseAll
    })
  }, [collapseAll])

  useEffect(() => {
    getData()
    setSelectAll(false)
  }, [reloadData])

  useEffect(() => {
    setOffset(0)
    setHasMore(true)
    setData(null)
    setReloadData(new Date())
  }, [searchParams])

  useEffect(() => {
    handleSelectionChanged(null)
  }, [data])

  const getData = () => {
    if (offset === null) {
      return
    }
    const queryParams = searchParams.toString()
    api.get(`debt?${queryParams}`, {
      params: {
        offset
      }
    })
      .then(res => {
        let newData: IAccountDebt[]
        newData = (res.data as IAccountDebt[])
        if (newData.length < 30) {
          setHasMore(false)
        }
        if (data !== null) {
          newData = data?.concat(res.data as IAccountDebt[])
        }
        setOffset(newData.length)
        setData(newData)
      }
      )
      .catch(e => { console.log(e) }
      )
    api.get(`debt/total?${queryParams}`)
      .then(res => {
        handleTotal(res.data.count)
      }
      )
      .catch(e => { console.log(e) }
      )
  }

  const dataLen = (data: any): number => {
    if (data != null) {
      return data.length
    }
    return 0
  }

  const handleAccountRowSelectionChanged = (row: IDebt[] | null) => {
    const checkedRows: IDebt[] = []
    data?.forEach(account => {
      account.debt?.forEach(debt => {
        if (debt.isChecked as boolean) {
          checkedRows?.push(debt)
        }
      })
    })
    handleSelectionChanged(checkedRows)
  }

  const getDownloadUrl = (): string => {
    const queryParams = searchParams.toString()
    return process.env.REACT_APP_API_URL + `/debt/download?${queryParams}`
  }

  return (
        <InfiniteScroll dataLength={dataLen(data)} next={getData} hasMore={hasMore} loader={
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner animation="border" variant="success" />
            </div>} style={{ overflow: 'unset' }}>
            <Table striped className="debt-table">
                <thead>
                <tr>
                    <th>{collapseAll
                      ? <FaAngleDown onClick={(e) => {
                        setCollapseAll(false)
                      }}/>
                      : <FaAngleUp onClick={(e) => {
                        setCollapseAll(true)
                      }}/>}</th>
                    <th>ІПН</th>
                    <th>ПІБ</th>
                    <th className="text-center">Оновлення</th>
                    <th className="text-end">ЄП</th>
                    <th className="text-end">ВЗ</th>
                    <th className="text-end">ЄСВ</th>
                    <th className="text-end">Нерух</th>
                    <th className="text-center">
                        <Stack direction="horizontal">
                            <div className="ms-auto mx-3">
                                <a href={getDownloadUrl()}>
                                    <FaRegFileExcel size={20} className="text-success d-flex flex-row-reverse"/>
                                </a>
                            </div>
                            <div><Form.Check onChange={(e: any) => {
                              setSelectAll(!selectAll)
                            }} checked={selectAll}/></div>
                        </Stack>
                    </th>
                </tr>
                </thead>
                <tbody>
                {data?.map(item => {
                  return (
                        <DebtTableRow key={item.id} row={item} checked={selectAll} collapsed={collapseAll} handleSelectionChanged={handleAccountRowSelectionChanged}></DebtTableRow>
                  )
                })}
                </tbody>
            </Table>
        </InfiniteScroll>
  )
}
export default DebtTable
