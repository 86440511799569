import { useParams } from 'react-router-dom'
import { type IDocument } from '../../interfaces/document'
import api from '../../api'
import { useEffect, useState } from 'react'
import F1300204 from './F1300204'
import F0103308 from './F0103308'
import F0133108 from './F0133108'
import F1499102 from './F1499102'
import F1391103 from './F1391103'
import F1391104 from './F1391104'
import FTEXT from './FTEXT'
import './documentView.scss'
import F0103309 from './F0103309'

const DocumentView = () => {
  const { id } = useParams()
  const [doc, setDoc] = useState<IDocument>({ id: 0, type: 'J0000000' } satisfies IDocument)

  useEffect(() => {
    api.get(`document/${id}`)
      .then(res => { setDoc(res.data as IDocument) }
      )
      .catch(e => {})
  }, [])

  return (
      <div>
          <div>{
              {
                F1300204: <F1300204 doc={doc}/>,
                F0103308: <F0103308 doc={doc}/>,
                F0103309: <F0103309 doc={doc}/>,
                F0133108: <F0133108 doc={doc}/>,
                F1499102: <F1499102 doc={doc}/>,
                J1499102: <F1499102 doc={doc}/>,
                F1391103: <F1391103 doc={doc}/>,
                F1391104: <F1391104 doc={doc}/>,
                FTEXT: <FTEXT doc={doc}/>
              }[doc?.type]
          }
          </div>
          {doc?.attachments?.map((item, idx) => (
              <div className="pagebreak" key={item.id}>{
                  {
                    F1300204: <F1300204 doc={item}/>,
                    F0103308: <F0103308 doc={item}/>,
                    F0103309: <F0103309 doc={item}/>,
                    F0133108: <F0133108 doc={item}/>,
                    F1499102: <F1499102 doc={item}/>,
                    J1499102: <F1499102 doc={item}/>,
                    F1391103: <F1391103 doc={item}/>,
                    F1391104: <F1391104 doc={item}/>,
                    FTEXT: <FTEXT doc={item}/>
                  }[item?.type]
              }
              </div>
          )
          )}
</div>
  )
}

export default DocumentView
