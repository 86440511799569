export const getNotificationStatus = (status: string | undefined | null): string => {
  switch (status) {
    case 'OK':
      return 'В черзі'
    case 'IN_QUEUE':
      return 'В черзі'
    case 'DELIVERED':
      return 'Доставлено'
    case 'UNDELIVERED':
      return 'Не доставлено'
    case 'BLACKLISTED':
      return 'Чорний список'
    case 'NOT_CONFIRMED':
      return 'Не підтверджено'
    case 'UNSUBSCRIBED':
      return 'Відписався'
    case 'SPAM':
      return 'Спам'
    case 'READ':
      return 'Прочитано'
    case 'CLICKED':
      return 'Посилання'
  }
  return status ?? ''
}
