import { useEffect, useRef, useState } from 'react'
import api from '../../api'
import Modal from 'react-bootstrap/Modal'
import { Form, FormCheck, FormSelect, Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { type IDebt } from '../../interfaces/debt'
import { type EmailTemplate } from '../../interfaces/eSputnik'
import './sendNotificationModal.scss'

interface SendNotificationModalProps {
  show: boolean
  selectedRows: IDebt[]
  handleClose: (needReloadDataAfterClose: boolean) => void
}

const getDefaultPeriodData = (): string => {
  const prevMonthDate = new Date()
  prevMonthDate.setDate(0)

  const prevYear = prevMonthDate.getFullYear()
  const prevMonth = prevMonthDate.getMonth()

  let periodName = ''

  if ([0, 1, 2].includes(prevMonth)) {
    periodName = '1'
  } else if ([3, 4, 5].includes(prevMonth)) {
    periodName = '2'
  } else if ([6, 7, 8].includes(prevMonth)) {
    periodName = '2'
  } else if ([9, 10, 11].includes(prevMonth)) {
    periodName = '4'
  }
  return `за ${periodName} квартал ${prevYear} року`
}

const SendNotificationModal = ({ show, selectedRows, handleClose }: SendNotificationModalProps) => {
  const [actionSpinner, setActionSpinner] = useState<string>('')
  const [emailTemplateList, setEmailTemplateList] = useState<EmailTemplate[]>([])
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate | null>(null)
  const [isTestSending, setIsTestSending] = useState<boolean>(true)
  const [testEmail, setTestEmail] = useState<string>('')
  const form = useRef<HTMLFormElement>(null)
  const [isFormValid, setIsFormValid] = useState<boolean>(true)
  const [periodData, setPeriodData] = useState<string>(getDefaultPeriodData())

  useEffect(() => {
    getEmailTemplateList()
    setIsTestSending(true)
  }, [])

  useEffect(() => {
    setPeriodData(getDefaultPeriodData)
    setIsTestSending(true)
  }, [show])

  useEffect(() => {
    if (emailTemplateList.length > 0) {
      setEmailTemplate(emailTemplateList[0])
    }
  }, [emailTemplateList])

  const sendEmail = () => {
    if (isTestSending && form?.current?.checkValidity() === false) {
      setIsFormValid(false)
      return
    }

    setActionSpinner('send-email')

    let data = {}
    if (selectedRows.length !== 0) {
      data = {
        ids: selectedRows.map((item) => { return item.id }),
        templateId: emailTemplate?.id,
        testEmail: isTestSending ? testEmail : null,
        periodData: periodData !== '' ? periodData : null
      }
    }

    api.post('/debt/send-email', data)
      .then(res => {
        handleClose(true)
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => { setActionSpinner('') })
  }

  const getEmailTemplateList = () => {
    api.get('e-sputnik/email-templates')
      .then(res => { setEmailTemplateList(res.data as EmailTemplate[]) }
      )
      .catch(e => {})
  }

  return (
        <Modal size={'lg'} className="send-notification-modal" show={show} onHide={() => { handleClose(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Відправити квитанції</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form ref={form}>
                    <FormSelect onChange={(e: any) => {
                      setEmailTemplate(emailTemplateList.find(item => item.id === Number(e.target.value)) ?? null)
                    }}>
                        {emailTemplateList.map((item, i) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </FormSelect>
                    <Form.Group className="form-group mt-2">
                        <Form.Label>Тема листа:</Form.Label>
                        <Form.Text className="form-text">{emailTemplate?.subject}</Form.Text>
                    </Form.Group>
                    <Form.Group className="form-group mt-2">
                        <Form.Label>Відправник:</Form.Label>
                        <Form.Text className="form-text">{emailTemplate?.from}</Form.Text>
                    </Form.Group>
                    <Form.Group className="form-group mt-2">
                        <Form.Label>Період:</Form.Label>
                        <Form.Control className="input-container"
                                      value={periodData}
                                      onChange={(e) => { setPeriodData(e.currentTarget.value) }}
                        />
                    </Form.Group>
                    <Form.Group className="form-group mt-3">
                        <FormCheck type={'switch'}
                                   label={'Тестова відправка'}
                                   checked={isTestSending}
                                   onChange={(e) => { setIsTestSending(!isTestSending) }}/>
                        <Form.Control className="input-container"
                                      type="email"
                                      value={testEmail}
                                      hidden={!isTestSending}
                                      onChange={(e) => { setTestEmail(e.currentTarget.value) }}
                                      required
                                      isInvalid={ isTestSending && !isFormValid }/>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="reject" onClick={(e: any) => { handleClose(false) }}>Close</Button>
                <Button variant="success" onClick={sendEmail} form="main-form">
                    {actionSpinner === 'importAccount' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Save
                </Button>
            </Modal.Footer>
        </Modal>
  )
}

export default SendNotificationModal
