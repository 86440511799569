import { Form, Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import api from '../../api'

interface GoogleImportAccountModalProps {
  show: boolean
  handleClose: (needReloadDataAfterClose: boolean) => void
}

const GoogleImportAccountModal = ({ show, handleClose }: GoogleImportAccountModalProps) => {
  const [importType, setImportType] = useState<string>('import')
  const [fileUrl, setFileUrl] = useState<File>()
  const [needUpdate, setNeedUpdate] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [actionSpinner, setActionSpinner] = useState<string>('')

  const importAccount = () => {
    setActionSpinner('importAccount')
    api.post('/accounts/import-from-googlesheet', { fileUrl, needUpdate })
      .then(res => {
        handleClose(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
      .finally(() => { setActionSpinner('') })
  }

  const activeAccount = () => {
    setActionSpinner('importAccount')
    api.post('/accounts/active-from-googlesheet', { fileUrl, isActive })
      .then(res => {
        handleClose(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
      .finally(() => { setActionSpinner('') })
  }

  const emailAccount = () => {
    setActionSpinner('importAccount')
    api.post('/accounts/email-from-googlesheet', { fileUrl })
      .then(res => {
        handleClose(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
      .finally(() => { setActionSpinner('') })
  }

  return (
        <Modal show={show} onHide={() => { handleClose(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Імпорт з Google sheets</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Check name='import-type' type='radio' id='import' label={'Імпорт ФОП'}
                                    checked={importType === 'import'}
                                    onChange={(e) => { setImportType('import') }}/>
                        <Form.Check name='import-type' type='radio' id='active' label={'ФОП Активні/Неактивні'}
                                    checked={importType === 'active'}
                                    onChange={(e) => { setImportType('active') }}/>
                        <Form.Check name='import-type' type='radio' id='email' label={'Email'}
                                    checked={importType === 'email'}
                                    onChange={(e) => { setImportType('email') }}/>
                    </Form.Group>
                    {{
                      import: <>
                            <Form.Group className="mb-3">
                                <Form.Label>Посилання на файл</Form.Label>
                                <Form.Control type="input" onChange={(e: any) => { setFileUrl(e.target.value) }}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Оновити дані з ДПС для існуючих ФОП</Form.Label>
                                <Form.Check checked={needUpdate} onChange={(e: any) => { setNeedUpdate(e.target.checked) }}/>
                            </Form.Group>
                        </>,
                      active: <>
                            <Form.Group className="mb-3">
                                <Form.Label>Посилання на файл</Form.Label>
                                <Form.Control type="input" onChange={(e: any) => { setFileUrl(e.target.value) }}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Активні</Form.Label>
                                <Form.Check checked={isActive} onChange={(e: any) => { setIsActive(e.target.checked) }}/>
                            </Form.Group>
                        </>,
                      email: <>
                            <Form.Group className="mb-3">
                                <Form.Label>Посилання на файл</Form.Label>
                                <Form.Control type="input" onChange={(e: any) => { setFileUrl(e.target.value) }}/>
                            </Form.Group>
                        </>
                    }[importType]}

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="reject" onClick={(e: any) => { handleClose(false) }}>Close</Button>
                <Button variant="success" onClick={
                    {
                      import: importAccount,
                      active: activeAccount,
                      email: emailAccount
                    }[importType]}>
                    {actionSpinner === 'importAccount' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Save
                </Button>
            </Modal.Footer>
        </Modal>
  )
}

export default GoogleImportAccountModal
