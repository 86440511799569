import type { IAccountKeyFile } from './account'

export interface IDebt {
  id: number
  updatedAt?: string
  reportYear: number
  namePlt: string
  code: number
  stiName: string
  narah0: number
  narahEnd: number
  nedoim0: number
  nedoimEnd: number
  perepl0: number
  pereplEnd: number
  penia0: number
  peniaEnd: number
  splbd0: number
  splbdEnd: number
  notificationData?: {
    updatedAt?: string
    status?: string
  }

  isChecked: boolean
  taxAmount: number
}

export const debtByCode = (debt: IDebt[] | undefined, code: number): IDebt | undefined => {
  return debt?.find((item) => { return item.code === code })
}

export interface IAccountDebt {
  id: number
  name: string
  ipnCode: string
  keyFiles?: IAccountKeyFile[]
  debt?: IDebt[]
  email?: string

  incomeStatementAmount: number
  socialTaxAmount: number
  militaryTaxAmount: number
  realEstateTaxAmount: number

  isShowDetails: boolean
  debtUpdatedAt?: string
}
