import { Button, ButtonGroup, Col, Container, Form, InputGroup, Row, Stack, ToggleButton } from 'react-bootstrap'
import { FaSearch, FaTimes } from 'react-icons/fa'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import api from '../../api'
import DebtTable from './debtTable'
import { type IDebt } from '../../interfaces/debt'
import './debt.scss'
import SendNotificationModal from './sendNotificationModal'

const Debt = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchString, setSearchString] = useState<string>(searchParams.get('ipnCode') ?? '')
  const [selectedRows, setSelectedRows] = useState<IDebt[] | null>(null)
  const [search, setSearch] = useState<string>(searchParams.get('search') ?? '')
  const [total, setTotal] = useState<number>(0)
  const [filterIncomeStatementTax, setFilterIncomeStatementTax] = useState<boolean>(searchParams.has('incomeStatementTax'))
  const [filterMilitaryTax, setFilterMilitaryTax] = useState<boolean>(searchParams.has('militaryTax'))
  const [filterSocialTax, setFilterSocialTax] = useState<boolean>(searchParams.has('socialTax'))
  const [filterSocialTaxAmount, setFilterSocialTaxAmount] = useState<number>(Number(searchParams.get('search') ?? '0'))
  const filterSocialTaxAmountRef = useRef<HTMLInputElement>(null)
  const [filterRealEstateTax, setFilterRealEstateTax] = useState<boolean>(searchParams.has('realEstateTax'))
  const [filterNotSent, setFilterNotSent] = useState<boolean>(searchParams.get('notSent') === 'true')
  const [showNotification, setShowNotification] = useState<boolean>(false)

  useEffect(() => {
    if (searchString !== '') {
      searchParams.set('search', searchString)
      setSearchParams(searchParams)
    } else if (searchParams.has('search')) {
      searchParams.delete('search')
      setSearchParams(searchParams)
    }
  }, [searchString])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setSearchString(search)
    }, 300)
    return () => { clearTimeout(timeOutId) }
  }, [search])

  useEffect(() => {
    if (filterIncomeStatementTax) {
      searchParams.set('incomeStatementTax', '0')
      setSearchParams(searchParams)
    } else {
      searchParams.delete('incomeStatementTax')
      setSearchParams(searchParams)
    }
  }, [filterIncomeStatementTax])

  useEffect(() => {
    if (filterMilitaryTax) {
      searchParams.set('militaryTax', '0')
    } else {
      searchParams.delete('militaryTax')
    }
    setSearchParams(searchParams)
  }, [filterMilitaryTax])

  useEffect(() => {
    if (filterSocialTax) {
      searchParams.set('socialTax', String(filterSocialTaxAmount))
      if (filterSocialTaxAmountRef !== null) {
        filterSocialTaxAmountRef.current?.focus()
        filterSocialTaxAmountRef.current?.select()
      }
    } else {
      searchParams.delete('socialTax')
    }
    setSearchParams(searchParams)
  }, [filterSocialTax])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (filterSocialTax) {
        searchParams.set('socialTax', String(filterSocialTaxAmount))
      } else {
        searchParams.delete('socialTax')
      }
      setSearchParams(searchParams)
    }, 300)
    return () => { clearTimeout(timeOutId) }
  }, [filterSocialTaxAmount])

  useEffect(() => {
    if (filterRealEstateTax) {
      searchParams.set('realEstateTax', '0')
    } else {
      searchParams.delete('realEstateTax')
    }
    setSearchParams(searchParams)
  }, [filterRealEstateTax])

  useEffect(() => {
    if (filterNotSent) {
      searchParams.set('notSent', 'true')
    } else {
      searchParams.delete('notSent')
    }
    setSearchParams(searchParams)
  }, [filterNotSent])

  const reloadData = () => {
    searchParams.set('reloadTime', String((new Date()).getTime()))
    setSearchParams(searchParams)
  }

  const handleSelectionChanged = (data: IDebt[] | null) => {
    setSelectedRows(data)
  }

  const handleTotal = (value: number) => {
    setTotal(value)
  }

  const handleUpdateDebt = () => {
    api.post('debt/updateAll')
      .then(res => {
      })
      .catch(e => {})
  }

  const handleCloseSendNotificationModal = (needReloadData: boolean) => {
    if (needReloadData) {
      reloadData()
    }
    setShowNotification(false)
  }

  return (

        <Container className="pageContainer debt">
            <Row>
                <Stack direction="horizontal">
                    <div className="mx-4 pt-3 lh-1">
                        <p className="fs-3 fw-bold">{total}</p>
                    </div>
                    <div className="p-2">
                        <InputGroup className="search-container">
                            <Form.Control
                                className="search-input"
                                placeholder="Пошук..."
                                value={search}
                                onChange={(e: any) => {
                                  setSearch(e.target.value)
                                }}
                            />
                            {search !== ''
                              ? <FaTimes className="icon" onClick={(e: any) => {
                                setSearch('')
                              }}/>
                              : <FaSearch className="icon"/>}
                        </InputGroup>
                    </div>
                    <div className="p-2 ms-auto">
                        <Button className="add-button" disabled={(selectedRows?.length ?? 0) === 0} onClick={() => { setShowNotification(true) }}>Відправити квитанції({selectedRows?.length})</Button>
                        <SendNotificationModal show={showNotification} selectedRows={selectedRows!} handleClose={handleCloseSendNotificationModal}/>
                        <Button className="add-button mx-3" onClick={(e) => { handleUpdateDebt() }}>Оновити стан розрахунків</Button>
                    </div>
                </Stack>
            </Row>
            <Row>
                <Col className="px-4 py-2">
                    <ButtonGroup size="sm">
                        <ToggleButton
                            style={{ minWidth: '9em' }}
                            key="toggle-check-income-statement-tax"
                            id="toggle-check-income-statement-tax"
                            type="checkbox"
                            variant={'outline-danger'}
                            checked={filterIncomeStatementTax}
                            value="1"
                            onChange={(e) => {
                              setFilterIncomeStatementTax(e.currentTarget.checked)
                            }}
                        >ЄП</ToggleButton>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="ps-3">
                        <ToggleButton
                            style={{ minWidth: '9em' }}
                            key="toggle-check-military-tax"
                            id="toggle-check-military-tax"
                            type="checkbox"
                            variant={'outline-danger'}
                            checked={filterMilitaryTax}
                            value="1"
                            onChange={(e) => {
                              setFilterMilitaryTax(e.currentTarget.checked)
                            }}
                        >ВЗ</ToggleButton>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="ps-3">
                        <ToggleButton
                            style={{ minWidth: '9em' }}
                            key="toggle-check-social-tax"
                            id="toggle-check-social-tax"
                            type="checkbox"
                            variant={'outline-danger'}
                            checked={filterSocialTax}
                            value="1"
                            onChange={(e) => {
                              setFilterSocialTax(e.currentTarget.checked)
                            }}
                        >ЄСВ</ToggleButton>
                        <InputGroup size="sm">
                            <InputGroup.Text>&lt;</InputGroup.Text>
                            <Form.Control
                                ref={filterSocialTaxAmountRef}
                                name="filterSocialTaxAmount1"
                                className="no-spinners"
                                size="sm"
                                style={{ maxWidth: '100px' }}
                                type="number"
                                disabled={!filterSocialTax}
                                value={filterSocialTaxAmount}
                                onChange={(e) => {
                                  setFilterSocialTaxAmount(Number(e.currentTarget.value))
                                }}
                            />
                        </InputGroup>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="ps-3">
                        <ToggleButton
                            style={{ minWidth: '9em' }}
                            key="toggle-check-realestate-tax"
                            id="toggle-check-realestate-tax"
                            type="checkbox"
                            variant={'outline-danger'}
                            checked={filterRealEstateTax}
                            value="1"
                            onChange={(e) => {
                              setFilterRealEstateTax(e.currentTarget.checked)
                            }}
                        >Нерух.</ToggleButton>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="ps-5">
                        <ToggleButton
                            style={{ minWidth: '9em' }}
                            key="toggle-check-not-sent"
                            id="toggle-check-not-sent"
                            type="checkbox"
                            variant={'outline-warning'}
                            checked={filterNotSent}
                            value="1"
                            onChange={(e) => {
                              setFilterNotSent(e.currentTarget.checked)
                            }}
                        >Не відправлені</ToggleButton>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="account-table">
                <DebtTable searchParams={searchParams}
                                handleSelectionChanged={handleSelectionChanged} handleTotal={handleTotal}></DebtTable>
            </Row>
        </Container>

  )
}

export default Debt
