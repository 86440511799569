import { Collapse, Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { type IAccountDebt, type IDebt } from '../../interfaces/debt'
import KeyFileLogin from '../keyFileLogin/keyFileLogin'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { convertISODateToDateString } from '../../utils/convert'
import DebtTableRowItem from './debtTableRowItem'
import { IoWarningOutline } from 'react-icons/io5'

export interface DebtTableRowProps {
  row: IAccountDebt
  checked: boolean
  collapsed: boolean
  handleSelectionChanged: (row: IDebt[] | null) => void
}

const DebtTableRow = ({ row, checked, collapsed, handleSelectionChanged }: DebtTableRowProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(false)

  useEffect(() => {
    setShowDetails(!collapsed)
  }, [collapsed])

  useEffect(() => {
    row.isShowDetails = showDetails
  }, [showDetails])

  useEffect(() => {
    row.debt?.forEach(item => {
      item.taxAmount = item.pereplEnd - item.nedoimEnd
    })
    row.debtUpdatedAt = row?.debt?.[0].updatedAt
  }, [row])

  const handleRowItemSelectionChanged = (item: IDebt | null) => {
    row?.debt?.forEach(debt => {
      if (debt.id === item?.id) {
        debt.isChecked = item?.isChecked
      }
    })
    handleSelectionChanged(row?.debt?.filter(item => { return item.isChecked }) ?? null)
  }

  return [
      <tr key={row.id}>
          <td onClick={(e) => {
            setShowDetails(!showDetails)
          }}>{showDetails ? <FaAngleUp/> : <FaAngleDown/>}</td>
          <td>
              <div className="d-flex">
                  {row.ipnCode}
                  {
                      ((new Date(row.keyFiles![0].certEndTime)).getTime() > (new Date()).getTime()) &&
                      <KeyFileLogin keyFile={row.keyFiles![0]}/>
                  }
              </div>
          </td>
          <td>
              <a href={window.location.origin + `/account/${row.id}`} target="_blank"
                 rel="noreferrer">{row.name}</a>
          </td>
          <td className="text-center">{convertISODateToDateString(row.debtUpdatedAt)}</td>
          <td data-value={row.incomeStatementAmount < 0 ? 'debt' : row.incomeStatementAmount > 0 ? 'overpayment' : 'zero'}
              className="text-end tax-amount-column">
              <div hidden={showDetails}>
                  {row.incomeStatementAmount.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}
              </div>
          </td>
          <td data-value={row.militaryTaxAmount < 0 ? 'debt' : row.militaryTaxAmount > 0 ? 'overpayment' : 'zero'}
              className="text-end tax-amount-column">
              <div hidden={showDetails}>
                  {row.militaryTaxAmount.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}
              </div>
          </td>
          <td data-value={row.socialTaxAmount < 0 ? 'debt' : row.socialTaxAmount > 0 ? 'overpayment' : 'zero'}
              className="text-end tax-amount-column">
              <div hidden={showDetails}>
                  {row.socialTaxAmount.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}
              </div>
          </td>
          <td data-value={row.realEstateTaxAmount < 0 ? 'debt' : row.realEstateTaxAmount > 0 ? 'overpayment' : 'zero'}
              className="text-end tax-amount-column">
              <div hidden={showDetails}>
                  {row.realEstateTaxAmount.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}
              </div>
          </td>
          <td></td>
          <td>
              {row.email === undefined && <IoWarningOutline style={{ color: 'red' }}/>}
          </td>
      </tr>,
      <Collapse key={row.id * 1000} in={showDetails}>
          <tr>
              <td colSpan={10}>
                  <Table className="debt-list-table">
                      <tbody>
                      {row.debt?.map(item => {
                        return (
                            <DebtTableRowItem key={item.id} row={item} checked={checked} handleSelectionChanged={handleRowItemSelectionChanged}/>
                        )
                      })}
                      </tbody>
                  </Table>

              </td>
          </tr>
      </Collapse>
  ]
}

export default DebtTableRow
