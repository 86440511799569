import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'
import React from 'react'
import logo from '../../logo.svg'
import './appBar.scss'
import { useAuth } from '../../hooks/authProvider'

const AppBar = () => {
  const auth = useAuth()

  const logout = (e: any) => {
    e.preventDefault()
    auth.logout().catch((error) => { console.error(error) })
  }

  const getLink = (to: string): string => {
    if (window.location.pathname === to) {
      return to + window.location.search
    }
    return to
  }

  return (
      <>
          <Navbar expand="lg" className="main-navbar navbar-expand-md">
              <Navbar.Brand>
                  <img src={logo} alt="logo" className="logo"/>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="container-fluid" variant="pills">
                          <Nav.Link as={Link} to={getLink('/account')} className="d-flex justify-content-center" eventKey="account">
                              ФОП
                          </Nav.Link>

                          <Nav.Link as={Link} to={getLink('/digitalSignature')} className="d-flex justify-content-center" eventKey="digitalSignature">
                              ЕЦП
                          </Nav.Link>

                          <Nav.Link as={Link} to={getLink('/incomeStatement')} className="d-flex justify-content-center" eventKey="incomeStatement">
                              Звіт про доходи
                          </Nav.Link>

                          <Nav.Link as={Link} to={getLink('/debt')} className="d-flex justify-content-center" eventKey="debt">
                              Стан розрахунків
                          </Nav.Link>

                          <Nav.Link as={Link} to={getLink('/dashboard')} className="d-flex justify-content-center" eventKey="dashboard">
                              Dashboard
                          </Nav.Link>

                      <NavDropdown title={auth.user?.name} className="ms-auto user-item">
                          <NavDropdown.Item onClick={logout}>
                              Logout
                          </NavDropdown.Item>
                      </NavDropdown>
                  </Nav>
              </Navbar.Collapse>
          </Navbar>
          <Outlet />
      </>

  )
}

export default AppBar
