import { convertISODateToDatetimeString } from '../../utils/convert'
import { getNotificationStatus } from '../../utils/debt'
import { Form } from 'react-bootstrap'
import type { IDebt } from '../../interfaces/debt'
import { useEffect, useState } from 'react'
import './debtTableRowItem.scss'

export interface DebtTableRowItemProps {
  row: IDebt
  checked: boolean
  handleSelectionChanged: (row: IDebt | null) => void
}

const DebtTableRowItem = ({ row, checked, handleSelectionChanged }: DebtTableRowItemProps) => {
  const [selected, setSelected] = useState<boolean>(false)

  useEffect(() => {
    setSelected(checked)
  }, [checked])

  useEffect(() => {
    row.isChecked = selected
    handleSelectionChanged(row)
  }, [selected])

  return [
    <tr key={row.id} className="debt-table-row-item">
        <td className="text-start sti-name-column">{row.stiName}</td>
        <td className="text-start name-plt-column">{row.code} &nbsp;&nbsp;&nbsp; {row.namePlt}</td>
        <td data-value={row.taxAmount < 0 ? 'debt' : row.taxAmount > 0 ? 'overpayment' : 'zero'}
            className="text-end tax-amount-column">{row.taxAmount?.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</td>
        <td className="text-end notification-column">
            <div
                hidden={(row.notificationData === null || row.notificationData === undefined)}>
                Квитанція: {convertISODateToDatetimeString(row.notificationData?.updatedAt)} - {getNotificationStatus(row.notificationData?.status)}
            </div>
        </td>
        <td className="text-end"><Form.Check.Input checked={selected} onChange={(e) => {
          setSelected(!selected)
        }}/></td>
    </tr>
  ]
}

export default DebtTableRowItem
